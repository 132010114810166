import React, { useState } from "react";
import { useDispatch } from "react-redux";
import image from "../../assets/images/school-management-tech-system.png";
import Header from "../../components/Header";
import Login from "../../components/Login";
import { FetchLiveStructures } from "../../Network/StructureApi";
import Actives from "../../components/Actives/Actives";
import Subs from "../../components/Subs/Subs";
import Insts from "../../components/Insts/Insts";

const Home = () => {
	//redux dispatch
	const dispatch = useDispatch();
	const [Data, setData] = useState({ userName: "", password: "" });
	const [Loading, setLoading] = useState(false);
	const [Verified, setVerified] = useState(false);

	const CheckData = async () => {
		setLoading(true);

		await FetchLiveStructures(
			`https://${Data.userName.split("/")[0]}/pro/${
				Data.userName.split("/")[1]
			}`,
			Data.password,
			dispatch,
			setVerified
		);

		setLoading(false);
	};

	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "100vw",

				backgroundImage: `url(${image})`,
				backgroundSize: "cover",
				backgroundRepeat: "repeat-y",
				backgroundPosition: "center",
			}}
		>
			<Header></Header>
			<div
				style={{
					marginTop: "40px",
					backgroundColor: "rgba(64, 99, 112, 0.5)",
					minHeight: "100vh",
					minWidth: "100vw",
				}}
			>
				{Verified && Data.userName.split("/")[1] === "actives" ? (
					<Actives
						data={Data}
						LoadData={CheckData}
						Loading={Loading}
						LogOut={setVerified}
						Link={`https://${Data.userName.split("/")[0]}/pro`}
					></Actives>
				) : Verified && Data.userName.split("/")[1] === "subs" ? (
					<Subs
						data={Data}
						LoadData={CheckData}
						Loading={Loading}
						LogOut={setVerified}
						Link={`https://${Data.userName.split("/")[0]}/pro`}
					></Subs>
				) : Verified && Data.userName.split("/")[1] === "insts" ? (
					<Insts
						LoadData={CheckData}
						Loading={Loading}
						LogOut={setVerified}
						Link={`https://${Data.userName.split("/")[0]}/pro`}
					></Insts>
				) : (
					<div style={{ paddingTop: "20vh" }}>
						{" "}
						<Login
							data={Data}
							CheckData={CheckData}
							updateData={setData}
							Loading={Loading}
						></Login>
					</div>
				)}
			</div>
		</div>
	);
};

export default Home;
