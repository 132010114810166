import React from "react";

const Inst = ({ inst }) => {
  return (
    <tr key={inst.linker}>
      <td colSpan={2}>{inst.name}</td>

      <td colSpan={2}>
        {new Date(parseInt(inst.linker)).toLocaleDateString("en-US")}
      </td>
    </tr>
  );
};

export default Inst;
