import React, { useState } from "react";
import swal from "sweetalert";
import { updateLiveStructure } from "../../Network/StructureApi";
import { useDispatch } from "react-redux";

const Active = ({ active, Data, Link }) => {
	//redux dispatch
	const dispatch = useDispatch();
	const [Loading, setLoading] = useState(false);

	const VerifyActive = async () => {
		await swal("Enter Amount", {
			content: "input",
		}).then(async (amount) => {
			await swal({
				title: `Verify SoftwareId ${active.softwareId}`,
				text: ` ${active.transaction} for ${amount}`,
				icon: "warning",
				buttons: true,
			})
				.then(async (willReturn) => {
					if (willReturn) {
						await updateLiveStructure(
							`${Link}/verify-v2`,
							Data.password,
							{ ...active, amount },
							dispatch
						);
					}
				})
				.catch((err) => console.log(err));
		});
	};

	const UpdateData = async (e) => {
		setLoading(true);
		if (e.target.value === "verify") {
			await VerifyActive();
		} else {
			await swal({
				title: `${e.target.value} SoftwareId ${active.softwareId}`,
				text: `Transaction code ${active.transaction} `,
				icon: "warning",
				buttons: true,
			})
				.then(async (willReturn) => {
					if (willReturn) {
						await updateLiveStructure(
							`${Link}/edit`,
							Data.password,
							{
								...active,
								deleted: e.target.value === "delete" ? 1 : 0,
								status: e.target.value === "reject" ? 2 : 0,
							},
							dispatch
						);
					}
				})
				.catch((err) => console.log(err));
		}

		setLoading(false);
	};
	return (
		<tr key={active.linker}>
			<td>{active.displayId}</td>
			<td>{active.transaction}</td>

			<td>
				{active.deleted != 0 ? (
					<span className="text-danger">Deleted</span>
				) : active.status == 0 ? (
					<span className="text-info">In Review</span>
				) : active.status == 2 ? (
					<span className="text-warning">Rejected</span>
				) : active.status == 1 ? (
					<span className="text-success">Verified</span>
				) : (
					""
				)}
			</td>
			<td>
				{active.status == 0 ? (
					<span className="text-info">Waiting...</span>
				) : (
					active.activation
				)}
			</td>
			<td>{new Date(parseInt(active.linker)).toLocaleDateString("en-US")}</td>
			<td>
				{Loading ? (
					<span className="spinner-border text-primary"></span>
				) : (
					<select className="form-control rounded" onChange={UpdateData}>
						<option value={""} className="text-info">
							Select Action
						</option>
						<option value={"review"} className="text-primary">
							Review
						</option>
						<option value={"verify"} className="text-success">
							Verify
						</option>
						<option value={"reject"} className="text-warning">
							Reject
						</option>
						<option value={"delete"} className="text-danger">
							Delete
						</option>
					</select>
				)}
			</td>
		</tr>
	);
};

export default Active;
