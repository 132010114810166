import swal from "sweetalert";
import { fetchStructures, updateStructure } from "../reducers/StructureSlice";

export const FetchLiveStructures = async (
  userName,
  password,
  dispatch,
  setVerified
) => {
  return await fetch(`${userName}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${userName}`,
    },
    body: JSON.stringify({
      userName,
      password,
      pro: password,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchStructures(res.subs || res.insts));
        setVerified(true);
      }
    })
    .catch(() => {});
};

export const updateLiveStructure = async (
  userName,
  password,
  structure,
  dispatch
) => {
  return await fetch(`${userName}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${userName}`,
    },
    body: JSON.stringify({
      ...structure,
      password,
      pro: password,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateStructure(res.active));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};
