import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";
import Sub from "./Sub";
const Subs = ({ data, LoadData, Loading, LogOut, Link }) => {
  const Structures = useSelector((state) => state.structure.structures);
  const [Search, setSearch] = useState("");
  const [Status, setStatus] = useState("all");

  const SearchSubs = Structures.map((structure) => ({
    ...structure,
    displayId: structure.softwareId.split("").reverse().join(""),
  }))
    .filter(
      (structure) =>
        (structure.displayId.toLowerCase().search(Search.toLowerCase()) > -1 ||
          (structure.code || "").toLowerCase().search(Search.toLowerCase()) >
            -1 ||
          Search === "") &&
        (parseInt(structure.deleted) === parseInt(Status) || Status === "all")
    )
    .sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div className="bg-light">
      {" "}
      <table className="table table-sm" id="subs">
        <thead>
          <tr>
            <th>
              <input
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Sub"
                className="form-control rounded"
              />
            </th>
            <th>
              <select
                className="form-control rounded"
                value={Status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={"all"} className="text-info">
                  All Status
                </option>
                <option value={"0"} className="text-success">
                  Active
                </option>
                <option value={"1"} className="text-danger">
                  Deleted
                </option>
              </select>
            </th>
            <th>{SearchSubs.length} Subs</th>
            <th>
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : (
                <button
                  className="btn btn-outline-info rounded"
                  onClick={() => LoadData()}
                >
                  Reload
                </button>
              )}
            </th>
            <th>
              <button
                className="btn btn-outline-danger rounded"
                onClick={() => LogOut(false)}
              >
                Logout
              </button>
            </th>
          </tr>
          <tr>
            <th>SoftwareId</th>
            <th>Status</th>
            <th>Activation Code</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            <PlainList
              list={SearchSubs}
              renderOnScroll
              renderItem={(structure) => (
                <Sub
                  key={structure.linker}
                  active={structure}
                  Data={data}
                  Link={Link}
                ></Sub>
              )}
            />
          }
        </tbody>
      </table>
      <GeneratePdf name={`subs`} id={"subs"}></GeneratePdf>
    </div>
  );
};

export default Subs;
