import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";
import Inst from "./Inst";
const Insts = ({ LoadData, Loading, LogOut }) => {
  const Structures = useSelector((state) => state.structure.structures);
  const [Search, setSearch] = useState("");

  const SearchInsts = Structures.map((structure) => ({
    ...structure,
  }))
    .filter(
      (structure) =>
        (structure.name || "").toLowerCase().search(Search.toLowerCase()) >
          -1 || Search === ""
    )
    .sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div className="bg-light">
      {" "}
      <table className="table table-sm" id="insts">
        <thead>
          <tr>
            <th>
              <input
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Inst"
                className="form-control rounded"
              />
            </th>

            <th>{SearchInsts.length} Insts</th>
            <th>
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : (
                <button
                  className="btn btn-outline-info rounded"
                  onClick={() => LoadData()}
                >
                  Reload
                </button>
              )}
            </th>
            <th>
              <button
                className="btn btn-outline-danger rounded"
                onClick={() => LogOut(false)}
              >
                Logout
              </button>
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Name</th>
            <th colSpan={2}>Date</th>
          </tr>
        </thead>
        <tbody>
          <PlainList
            list={SearchInsts}
            renderOnScroll
            renderItem={(structure) => (
              <Inst key={structure.linker} inst={structure}></Inst>
            )}
          />
        </tbody>
      </table>
      <GeneratePdf name={`insts`} id={"insts"}></GeneratePdf>
    </div>
  );
};

export default Insts;
