import React from "react";
import logo from "../assets/images/school-management-tech-system-logo.jpg";
import PwaInstall from "../Utils/PwaInstall";

const Header = () => {
  return (
    <div className="bg-secondary header " style={{ color: "white" }}>
      <div className=" d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {" "}
          <img
            src={logo}
            alt="school-management-tech-system-logo"
            className="rounded "
            style={{ maxHeight: "40px", width: "auto" }}
          />
          <p className="navbar-brand h5 mx-2">Techsystems </p>
        </div>

        <PwaInstall></PwaInstall>
      </div>
    </div>
  );
};

export default Header;
