import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FilePdf } from "react-bootstrap-icons";

const GeneratePdf = ({ id, name }) => {
  const [Loading, setLoading] = useState(false);

  const generatePdf = async () => {
    setLoading(true);
    var quots = document.getElementById(id);
    await html2canvas(quots).then(async (canvas) => {
      //make pdf
      var pdf = new jsPDF("p", "pt", "letter");

      for (var i = 0; i <= quots.clientHeight / 980; i++) {
        //html canvas stuff
        var srcImg = canvas;
        var sX = 0;
        var sY = 980 * i; //start 980 pixels down for every new page
        var sWidth = 900;
        var sHeight = 980;
        var dX = 0;
        var dY = 0;
        var dWidth = 900;
        var dHeight = 980;

        let onePageCanvas = document.createElement("canvas");
        onePageCanvas.setAttribute("width", 900);
        onePageCanvas.setAttribute("height", 980);

        var ctx = onePageCanvas.getContext("2d");

        await ctx.drawImage(
          srcImg,
          sX,
          sY,
          sWidth,
          sHeight,
          dX,
          dY,
          dWidth,
          dHeight
        );

        var canvasDataUrl = await onePageCanvas.toDataURL("image/png", 1.0);

        var width = onePageCanvas.width;
        var height = onePageCanvas.clientHeight;

        //if we are on anything other than the first page, add another page

        if (i > 0) {
          pdf.addPage("612", "791"); // 8.5 X 11 in pts (in*72)
        }

        //declare working on that page
        pdf.setPage(i + 1);

        //! now add content to that page
        await pdf.addImage(
          canvasDataUrl,
          "PNG",
          20,
          40,
          width * 0.62,
          height * 0.62
        );
      }

      await pdf.save(`${name}-${Date.now()}.pdf`);
    });
    setLoading(false);
  };
  return (
    <div className="d-flex justify-content-center">
      {Loading ? (
        <span className="spinner-border text-primary"></span>
      ) : (
        <button
          onClick={generatePdf}
          className="btn btn-sm btn-outline-primary rounded"
        >
          <FilePdf></FilePdf> Download Pdf
        </button>
      )}
    </div>
  );
};

export default GeneratePdf;
