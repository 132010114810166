import React, { useState } from "react";
import swal from "sweetalert";
import { updateLiveStructure } from "../../Network/StructureApi";
import { useDispatch } from "react-redux";

const Sub = ({ active, Data, Link }) => {
  //redux dispatch
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);

  const UpdateData = async (e) => {
    setLoading(true);

    await swal({
      title: `${active.deleted == 1 ? "Activate" : "Delete"} SoftwareId ${
        active.softwareId
      }`,
      icon: "warning",
      buttons: true,
    })
      .then(async (willReturn) => {
        if (willReturn) {
          await updateLiveStructure(
            `${Link}/update`,
            Data.password,
            {
              ...active,
              deleted: active.deleted == 1 ? 0 : 1,
            },
            dispatch
          );
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };
  return (
    <tr key={active.linker}>
      <td>{active.displayId}</td>
      <td>
        {active.deleted != 0 ? (
          <span className="text-danger">Deleted</span>
        ) : (
          <span className="text-success">Active</span>
        )}
      </td>
      <td>{active.code}</td>
      <td>{new Date(parseInt(active.linker)).toLocaleDateString("en-US")}</td>
      <td>
        {Loading ? (
          <span className="spinner-border text-danger"></span>
        ) : active.deleted == 0 ? (
          <button
            className="btn btn-outline-danger rounded"
            onClick={UpdateData}
          >
            Delete
          </button>
        ) : (
          <button
            className="btn btn-outline-success rounded"
            onClick={UpdateData}
          >
            Activate
          </button>
        )}
      </td>
    </tr>
  );
};

export default Sub;
