import React from "react";

const Login = ({ data, updateData, CheckData, Loading }) => {
  return (
    <div className="row d-flex justify-content-around mt-2">
      <div className="col-md-6">
        <div className="card">
          <div className="card-header">
            <p className="text-center h6">Login</p>
          </div>
          <di className="card-body">
            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                CheckData();
              }}
            >
              <div className="form-group">
                <label>UserName :</label>
                <input
                  placeholder="enter username"
                  value={data.name}
                  onChange={(e) =>
                    updateData({ ...data, userName: e.target.value })
                  }
                  className="form-control rounded"
                  type="text"
                  required
                />
              </div>
              <hr />
              <div className="form-group">
                <label>Password :</label>
                <input
                  placeholder="enter password"
                  value={data.password}
                  onChange={(e) =>
                    updateData({ ...data, password: e.target.value })
                  }
                  className="form-control rounded"
                  type="password"
                  required
                />
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                {!Loading ? (
                  <button
                    type="submit"
                    className="btn btn-outline-primary rounded"
                  >
                    login
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </form>
          </di>
        </div>
      </div>
    </div>
  );
};

export default Login;
