import { configureStore } from "@reduxjs/toolkit";
import { LoadState, SaveState } from "./Utils/LocalStorage";

import StructureSlice from "./reducers/StructureSlice";

import PwaSlice from "./reducers/PwaSlice";

const persistedState = LoadState();

const store = configureStore({
  reducer: {
    structure: StructureSlice,
    pwa: PwaSlice,
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  SaveState(store.getState());
});

export default store;
